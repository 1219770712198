// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".account-claims-wrapper_header_608ab0fa {\n  margin: 32px 0;\n}\n\n.account-claims-wrapper_paddings_8d141180 {\n  padding: 0 var(--w1);\n}\n\n.account-claims-wrapper_fileClaimContainer_6510d5a0 {\n\n  max-width: 1440px;\n  margin: 0 auto 48px;\n}\n\n.account-claims-wrapper_directDepositContainer_3689e0b4 {\n\n  max-width: 1440px;\n  margin: 40px auto 60px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n  .account-claims-wrapper_header_608ab0fa {\n    margin: 60px 0 ;\n  }\n\n  .account-claims-wrapper_fileClaimContainer_6510d5a0 {\n    margin-bottom: 112px;\n  }\n\n  .account-claims-wrapper_directDepositContainer_3689e0b4 {\n    margin-top: 60px;\n    margin-bottom: 96px;\n  }\n}", "",{"version":3,"sources":["webpack://src/claims/view/account-claims-wrapper/account-claims-wrapper.module.css"],"names":[],"mappings":"AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;;EAGE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;;EAGE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":["@value desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.header {\n  margin: 32px 0;\n}\n\n.paddings {\n  padding: 0 var(--w1);\n}\n\n.fileClaimContainer {\n  composes: paddings;\n\n  max-width: 1440px;\n  margin: 0 auto 48px;\n}\n\n.directDepositContainer {\n  composes: paddings;\n\n  max-width: 1440px;\n  margin: 40px auto 60px;\n}\n\n@media desktop {\n  .header {\n    margin: 60px 0 ;\n  }\n\n  .fileClaimContainer {\n    margin-bottom: 112px;\n  }\n\n  .directDepositContainer {\n    margin-top: 60px;\n    margin-bottom: 96px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"header": "account-claims-wrapper_header_608ab0fa",
	"paddings": "account-claims-wrapper_paddings_8d141180",
	"fileClaimContainer": "account-claims-wrapper_fileClaimContainer_6510d5a0 account-claims-wrapper_paddings_8d141180",
	"directDepositContainer": "account-claims-wrapper_directDepositContainer_3689e0b4 account-claims-wrapper_paddings_8d141180"
};
export default ___CSS_LOADER_EXPORT___;
