import { useState } from 'react';
import classnames from 'classnames';

import {
  CLAIM_STATUS_OPTIONS,
  CLAIM_TYPE_OPTIONS,
  useClaims,
} from '@pumpkincare/claims';
import { isUnique } from '@pumpkincare/shared';
import { Select, Spinner, Typography } from '@pumpkincare/shared/ui';
import { getUserPets, useUssr } from '@pumpkincare/user';

import ClaimInvoice from '../claim-invoice';
import Pagination from '../pagination';

import styles from './track-claims.module.css';

function TrackClaims() {
  const { data: petsData } = useUssr(getUserPets);

  const allOption = { label: 'All', value: 'All' };

  const statusOptions = [allOption, ...CLAIM_STATUS_OPTIONS];

  const typeOptions = [allOption, ...CLAIM_TYPE_OPTIONS];

  const petOptions = [
    allOption,

    ...petsData
      .reduce((pets, pet) => [...pets, pet], [])
      .filter(isUnique)
      .map(pet => ({ label: pet.name, value: pet.id })),
  ];

  const pageSizeOptions = [
    { label: '10', value: '10' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
  ];

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [range, setRange] = useState([0, 9]);
  const [filter, setFilter] = useState({ status: '' });

  const {
    data: { claims: claimsData, contentRange: contentRangeData },
    isFetching: isFetchingClaims,
  } = useClaims(filter, range);

  const { rangeFrom = 0, rangeTo = 9, claimsCount = 10 } = contentRangeData;

  function handlePageChange(newPage) {
    setRange([(newPage - 1) * pageSize, newPage * pageSize - 1]);
    setActivePage(newPage);
  }

  const contentClassName = classnames(styles.claimsContainer, {
    [styles.flexContent]: isFetchingClaims,
  });

  function handlePageSizeChange(value) {
    setRange([0, value - 1]);
    setActivePage(1);
    setPageSize(value);
  }

  function handleFilterChange(value, field) {
    const currentFilter = { ...filter };

    value === allOption.value
      ? delete currentFilter[field]
      : (currentFilter[field] = value);

    setFilter(currentFilter);
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Track Claims</h3>

      <div className={styles.content}>
        <div className={classnames(Typography.legalBody, styles.filtersContainer)}>
          <h4 className={styles.marginV}>Filters:</h4>

          <Select
            id='page-size'
            classes={{ root: styles.marginV }}
            defaultValue={pageSizeOptions[0]}
            options={pageSizeOptions}
            label='Page Size'
            onChange={({ value }) => handlePageSizeChange(value)}
          />

          <Select
            classes={{ root: styles.marginV }}
            defaultValue={allOption}
            options={petOptions}
            label='Pet'
            onChange={({ value }) => handleFilterChange(value, 'pet_id')}
          />

          <Select
            id='claim-type'
            classes={{ root: styles.marginV }}
            defaultValue={allOption}
            options={typeOptions}
            label='Claim Type'
            onChange={({ value }) => handleFilterChange(value, 'claim_type')}
          />

          <Select
            id='claim-status'
            classes={{ root: styles.marginV }}
            defaultValue={allOption}
            options={statusOptions}
            label='Claim Status'
            onChange={({ value }) => handleFilterChange(value, 'status')}
          />
        </div>

        <div className={contentClassName}>
          {isFetchingClaims ? <Spinner /> : null}
          {!isFetchingClaims && claimsData.length === 0 ? (
            <span>No Rows</span>
          ) : (
            <div className={styles.pagination}>
              {claimsData.length > 0 && !isFetchingClaims ? (
                <>
                  <Pagination
                    range={{ from: rangeFrom + 1, to: rangeTo + 1 }}
                    count={parseInt(claimsCount)}
                    currentPage={activePage}
                    onPageChange={handlePageChange}
                  />
                  {claimsData.map(claimData => (
                    <ClaimInvoice {...claimData} key={claimData.id} />
                  ))}
                  <Pagination
                    range={{ from: rangeFrom + 1, to: rangeTo + 1 }}
                    count={parseInt(claimsCount)}
                    currentPage={activePage}
                    onPageChange={handlePageChange}
                  />
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrackClaims;
